import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap } from 'gsap'
import * as TWEEN  from '@tweenjs/tween.js'
import { Raycaster } from 'three'
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'


// NOTES


// J'ai changé le fichier OrbitControls avec la fonction "rotation" pour permettre dns TWEEN le controls.rotate


/**
 * Base
 */

// warning

const starterText = document.getElementById('starterOk')
const starter = document.getElementById('starter')
starterText.onclick = function () {
    starter.classList.add('displayNone')
}


const contact = document.getElementById('popupContact')
const contactClick = document.getElementById('contact')
var isContactVisible = false
contactClick.onclick = function () {
    // console.log(isContactVisible)
    if (isContactVisible == false) {
        contact.classList.remove('displayNone')
        isContactVisible = true
    } else {
        contact.classList.add('displayNone')
        isContactVisible = false
    } 
}

const popupCloseContact = document.getElementById('popupContact')
popupCloseContact.onclick = function (){
    contact.classList.add('displayNone')

}

const loadingBarElement = document.querySelector('.loading-bar')

const logo = document.getElementById('logo')
logo.onclick = function () {
    // console.log(isContactVisible)
    if (isContactVisible == false) {
        contact.classList.remove('displayNone')
        isContactVisible = true
    } else {
        contact.classList.add('displayNone')
        isContactVisible = false
    } 
}


const swiper = new Swiper('.swiper-container', {
    // Optional parameters
    slidesPerView: 2,
    direction: 'horizontal',
    loop: true,
    // navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   },
})

// popup


const popup = document.getElementById('popupLobby')
const popupClose = document.getElementById('popupClose')
popupClose.onclick = function () {
    popup.classList.add('displayNone')
}
const popupLobby00 = document.getElementById('popupLobby00')
const popupCloseLobby00 = document.getElementById('popupCloseLobby00')
popupClose.onclick = function () {
    popupLobby00.classList.add('displayNone')
}

const popupStudio00 = document.getElementById('popupStudio00')
const popupCloseStudio00 = document.getElementById('popupCloseStudio00')
popupClose.onclick = function () {
    popupStudio00.classList.add('displayNone')
}
const popupStudio01 = document.getElementById('popupStudio01')
const popupCloseStudio01 = document.getElementById('popupCloseStudio01')
popupClose.onclick = function () {
    popupStudio01.classList.add('displayNone')
}
const popupWeb00 = document.getElementById('popupWeb00')
const popupCloseWeb00 = document.getElementById('popupCloseWeb00')
popupClose.onclick = function () {
    popupWeb00.classList.add('displayNone')
}
const popupWeb01 = document.getElementById('popupWeb01')
const popupCloseWeb01 = document.getElementById('popupCloseWeb01')
popupClose.onclick = function () {
    popupWeb01.classList.add('displayNone')
}


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color("white")

/**
 * Overlay
 */

const overlayGeometry = new THREE.PlaneBufferGeometry(2,2,1,1)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1}
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(1.0, 1.0, 1.0, uAlpha);
        }
    
    
    `

})
const overlay = new THREE.Mesh( overlayGeometry, overlayMaterial)
scene.add(overlay)

// Loaders: Pour mutualiser les Loaders



const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {   
        window.setTimeout( () => {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration : 1.5, value : 0 })
            loadingBarElement.classList.add('displayNone')
 
            logo.classList.remove('displayNone')
            starter.classList.remove('displayNone')
            controls.enabled  = true

            // console.log("loaded")
        }, 500)

        


    },

    // Progress
    ( itemsHTML,itemsLoaded, itemsTotal) => 
    {
        // console.log("loading in progress")
        const progressRatio = itemsLoaded/itemsTotal
        loadingBarElement.style.transform =`scale( ${progressRatio} )`
    },
    // Errors
    () =>
    {
        console.log('error')
    }
)

// Draco and GLTF Loaders

const dracoLoader = new DRACOLoader( loadingManager )
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader( loadingManager )
gltfLoader.setDRACOLoader(dracoLoader)

// Model




var model = new THREE.Object3D()
// let pickableMeshes = []
// console.log(pickableMeshes)
gltfLoader.load(
    '/models/scene_mini_final.glb',
    (gltf) =>
    {
        model = gltf.scene
        model.name = 'model'

        // Pushing les mesh dans pickableMesh
        // model.traverse( ( node ) => {

        //     if ( node instanceof THREE.Mesh && (/^library/i).test(node.name) ) { 
        //         pickableMeshes.push(node)
        //     }

    
        // })
        // Pour les ombres des models
        // gltf.scene.traverse( ( node ) => {

        //     if ( node.isMesh ) { 
        //         node.receiveShadow = true
        //     }
        //     if ( node.isMesh ) {
        //         node.castShadow = true 
        //     }
    
        // })

        // Change le material de la scene
        // var newMaterial = new THREE.MeshStandardMaterial({color: 0xffffff});
        // gltf.scene.traverse((o) => {
        //     if (o.isMesh) o.material = newMaterial
        // })
        
        scene.add(model)
        model.position.set(0,0,0)
        model.rotation.y = 2.8

        // console.log(model)
 


    }
)
var poi = new THREE.Object3D()

gltfLoader.load(
    '/models/poi.glb',
    (gltf) =>
    {
        poi = gltf.scene
        poi.name = 'poi'

        scene.add(poi)
        // poi.position.set(0,0,0)
        poi.rotation.y = 2.8

        // console.log(poi)
 


    }
)

// Group 


/**
 * Raycaster
 */

const raycaster = new THREE.Raycaster()



/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

// const arealight = new THREE.SpotLight( 0xffffff,.9)

// scene.add(arealight)
// arealight.position.set(0,20,0) 
// arealight.castShadow = false

 

 
/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Grid Helper
 */



/**
 * Mouse
 */


const mouse = new THREE.Vector2()

window.addEventListener('mousemove', (event)=> 
{
    mouse.x = event.clientX /sizes.width * 2 - 1
    mouse.y = - ( event.clientY /sizes.height ) * 2 + 1 

    // console.log(mouse)
})



/**
 * Camera
 */
// Base camera
const focal = 44 
const camera = new THREE.PerspectiveCamera(focal, sizes.width / sizes.height, .1, 30)
camera.position.set(4, 5, 4)
// camera.lookAt(0,6,0)
scene.add(camera)

// distance entre la camera et le 
// console.log(camera)

// Orbit Controls

const controls = new OrbitControls(camera, canvas)
controls.enabled  = false
controls.target.set(0,4,0)
// controls.enableDamping = true
// controls.enableZoom = true

controls.minDistance = 4
controls.maxDistance = 9

controls.minPolarAngle = 1.15
controls.maxPolarAngle = 1.3
// controls.minAzimuthAngle = .5
// controls.maxAzimuthAngle = 1

controls.enablePan = false
controls.dampingFactor = .8
// controls.update()




/**
 * Renderer
 */

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
})
// renderer.shadowMap.enabled = true
// renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// renderer.gammaOutput = true
renderer.outputEncoding = THREE.GammaEncoding
// renderer.gammaFactor = 2.2


var newMaterial = new THREE.MeshStandardMaterial({color: 0xffc300})

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

/**
 * Pick mesh
 */






/**
 * OnMouseclick scene
 */



function onMouseClick( event ) {
    raycaster.setFromCamera( mouse, camera );
    var isIntersected = raycaster.intersectObjects( poi.children, model.children);
    // var isIntersectedName = isIntersected[0].object.name
    // console.log(isIntersected[0].object.name)
    if (isIntersected.length > 0 && isIntersected[0].object.name == 'infolobby' ) {
        // const oldMaterial = isIntersected[0].object.material
        // console.log(isIntersected[0].object)
        new TWEEN.Tween(isIntersected[0].object.rotation)
        .to({
            // controls: controls.rotate ( 120 * Math.PI / 180 )
            // x:  isIntersected[0].object.position.x  + 0,
            y: isIntersected[0].object.rotation.y - (360 * Math.PI / 180),
            // z:  isIntersected[0].object.position.z + 0
        }, 1000)
        // .delay (1000)
        .start()
        .easing(TWEEN.Easing.Bounce.In)

        popup.classList.remove('displayNone')
        
    } else { 
        
        popup.classList.add('displayNone')
    }
    if (isIntersected.length > 0 && isIntersected[0].object.name == 'infolobby00' ) {
        // const oldMaterial = isIntersected[0].object.material
        // console.log(isIntersected[0].object)
        new TWEEN.Tween(isIntersected[0].object.rotation)
        .to({
            // controls: controls.rotate ( 120 * Math.PI / 180 )
            // x:  isIntersected[0].object.position.x  + 0,
            y: isIntersected[0].object.rotation.y - (360 * Math.PI / 180),
            // z:  isIntersected[0].object.position.z + 0
        }, 1000)
        // .delay (1000)
        .start()
        .easing(TWEEN.Easing.Bounce.In)

        popupLobby00.classList.remove('displayNone')
        
    } else { 
        

        popupLobby00.classList.add('displayNone')
    }

    if (isIntersected.length > 0 && isIntersected[0].object.name == 'infostudio00' ) {
        new TWEEN.Tween(isIntersected[0].object.rotation)
        .to({
            // controls: controls.rotate ( 120 * Math.PI / 180 )
            // x:  isIntersected[0].object.position.x  + 0,
            y: isIntersected[0].object.rotation.y + (360 * Math.PI / 180),
            // z:  isIntersected[0].object.position.z + 0
        }, 1000)
        // .delay (1000)
        .start()
        .easing(TWEEN.Easing.Bounce.In)
        popupStudio00.classList.remove('displayNone')

    } else {
        popupStudio00.classList.add('displayNone')
    }

    if (isIntersected.length > 0 && isIntersected[0].object.name == 'infostudio01' ) {
        new TWEEN.Tween(isIntersected[0].object.rotation)
        .to({
            // controls: controls.rotate ( 120 * Math.PI / 180 )
            // x:  isIntersected[0].object.position.x  + 0,
            y: isIntersected[0].object.rotation.y + (360 * Math.PI / 180),
            // z:  isIntersected[0].object.position.z + 0
        }, 1000)
        // .delay (1000)
        .start()
        .easing(TWEEN.Easing.Bounce.In)
        popupStudio01.classList.remove('displayNone')
    }  else {
        popupStudio01.classList.add('displayNone')
    }

    if (isIntersected.length > 0 && isIntersected[0].object.name == 'infodev00' ) {
        new TWEEN.Tween(isIntersected[0].object.rotation)
        .to({
            // controls: controls.rotate ( 120 * Math.PI / 180 )
            // x:  isIntersected[0].object.position.x  + 0,
            y: isIntersected[0].object.rotation.y + (360 * Math.PI / 180),
            // z:  isIntersected[0].object.position.z + 0
        }, 1000)
        // .delay (1000)
        .start()
        .easing(TWEEN.Easing.Bounce.In)
        popupWeb00.classList.remove('displayNone')
    }  else {
        popupWeb00.classList.add('displayNone')
    }
    
    if (isIntersected.length > 0 && isIntersected[0].object.name == 'infodev01' ) {
        new TWEEN.Tween(isIntersected[0].object.rotation)
        .to({
            // controls: controls.rotate ( 120 * Math.PI / 180 )
            // x:  isIntersected[0].object.position.x  + 0,
            y: isIntersected[0].object.rotation.y + (360 * Math.PI / 180),
            // z:  isIntersected[0].object.position.z + 0
        }, 1000)
        // .delay (1000)
        .start()
        .easing(TWEEN.Easing.Bounce.In)
        popupWeb01.classList.remove('displayNone')
    }  else {
        popupWeb01.classList.add('displayNone')
    }
    


    // }
    // isIntersected[0].object.material = newMaterial

}




window.addEventListener( 'click', () => {

    onMouseClick()
    // onMouseClickModel()

}, false );

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Yoyo info
    
    poi.position.y = Math.cos(elapsedTime) * .3

    // Update controls
    controls.update()
    




    // TWEEN update
    TWEEN.update()

    // Renderer
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

}

tick()